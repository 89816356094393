import 'slick-carousel';

if (document.querySelectorAll('[data-component="carousel"]').length > 0) {

    document.addEventListener('DOMContentLoaded', function () {

        $('#slickBanner').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
        });

        $('#slickServices').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            arrows: true,
            nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></div>',
            prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg></div>',
        });

        $('#slickChoose').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            nextArrow: $('#slickChooseNext'),
            prevArrow: $('#slickChoosePrev'),
            responsive: [{
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });

        $('#slickMobility').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            nextArrow: $('#slickMobilityNext'),
            prevArrow: $('#slickMobilityPrev'),
            responsive: [{
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });

        $('.slick--client').each(function(index, element){
            // console.log('Initializing carousel:', element);
            $(element).slick({
                dots: false,
                infinite: false,
                autoplay: false,
                // speed: 300,
                slidesToShow: 5,
                slidesToScroll: 1,
                nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></div>',
                prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-primary h-4" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg></div>',
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 3,
                        }
                    }, {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            });
        });

        $('#slickMilesParent').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            fade: true,
            asNavFor: '#slickMilesThumb'
        });

        $('#slickMilesThumb').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '#slickMilesParent',
            arrows: true,
            focusOnSelect: true,
            nextArrow: $('#slickMilesThumbNext'),
            prevArrow: $('#slickMilesThumbPrev'),
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    })
}